const drop = {

	elem: {
		drop: document.querySelectorAll('.drop')
	},

	init() {
		this.elem.drop.forEach(function(e) {
			e.innerHTML = e.innerHTML + '<span class="drop__outline" aria-hidden="true">' + e.innerText + '</span>';
		});
	}

};

export { drop };
