const timeline = {
	$window: $(window),

	elem: {
		$canvas: $('.canvas-bg'),
		$lines: $('.line-image'),
		stat: document.querySelectorAll('.stat')
	},

	fadein() {
		// console.log(window.innerHeight);
		let windowHeight = window.innerHeight / 2;
		let viewportBottom = $(window).scrollTop() + windowHeight;
		timeline.elem.stat.forEach(stat => {
			if($(stat).offset().top <= viewportBottom) {
				stat.classList.add('is-onscreen');
			}
		});
	},	

	init() {
		/* Timline init only on the timeline page  */
		let lTimeline = document.querySelectorAll('.timeline-row');
		if(!lTimeline.length){
			return;
		}

		/* Make sure the window is at top on page load */
		window.scrollTo(0, 0);
		
		let lScrollTop = $(window).scrollTop();
		let lDirection;
		let lLines = this.elem.$lines;
		lLines.attr('data-percent', 100);

		$(window).scroll(function () {
			let lCurrentScroll = $(window).scrollTop();
			if (lScrollTop < lCurrentScroll) {
				lDirection = 'down';
			} else {
				lDirection = 'up';
			}

			if (lDirection === 'down') {
				for (let x = 0; x < lLines.length; x = x + 1) {
					let lImage = lLines[x];
					let lStatus = lImage.getAttribute('data-animate');
					let lContinue = lImage.getAttribute('data-percent');

					if (!lStatus) {
						let lRect = lImage.getBoundingClientRect();
						let lTop = lImage.offsetTop - window.innerHeight / 2;
						let lHeight = lRect.height;
						let lPercent = lHeight / 100;

						if (lCurrentScroll >= lTop) {
							let lAmount = parseInt(
								100 - (lCurrentScroll - lTop) / lPercent
							);
							if (lContinue < lAmount) {
								lAmount = lContinue;
							}
							//console.log(lAmount, 'data =', lContinue)
							lImage.style.clipPath = `inset(0 0 ${lAmount}% 0)`;

							if (lAmount <= 50) {
								lImage.parentNode.classList.add('show-circle');
								/* add class for stat to show background */
								$(lImage).closest('.timeline-row').find('.stat').addClass('is-ready');
							}
							//console.log(lImage.classList, 'lContinue =', lContinue);
							/* Clip Path is done */
							if (lAmount <= 0) {
								lImage.setAttribute('data-animate', 'done');
							} else {
								lImage.setAttribute('data-percent', lAmount);
							}
						}
					}
				}
			}
			lScrollTop = lCurrentScroll;
		});

		$(window).on('load scroll resize', function() {
			timeline.fadein();
		});		

		// const lStats = [...this.elem.$stat];
		// const lObserver = new IntersectionObserver(pStats => {
		//	pStats.forEach(pStat => {
		//		pStat.target.classList.toggle('is-onscreen', pStat.isIntersecting);
		//		if(pStat.isIntersecting){
		//			lObserver.unobserve(pStat.target);
		//		}
		//		
		//	})
		// },{threshold: 1});
		// lStats.forEach(lStat => {
		//	lObserver.observe(lStat);
		// })
		

	}
};

export { timeline };
