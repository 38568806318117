const masthead = {
	$window: $(window),

	elem: {
		$bottom: $('.aggregate > .masthead-bottom, .level > .masthead-bottom'),
		$btn: $('.masthead__btn'),
		$container: $('.masthead__container'),
		$image: $('.masthead__image'),
		$accents: $('.image-accent'),
		$video: $('.masthead__video'),
	},

	init() {
		if (this.elem.$btn.length > 0) {
			this.bindUIActions();
		}

		if (this.elem.$bottom.length > 0) {
			let lNum = Math.floor(Math.random() * 3 + 1);
			this.elem.$bottom.addClass('pattern-' + lNum);
		}

		if(this.elem.$container.length > 0) {
			this.elem.$container.addClass('masthead__container--show');
		}

		if (this.elem.$accents.length) {
			let lNum = Math.floor(Math.random() * 3 + 1);
			let lPath = this.elem.$accents[0].src.split('accent')[0];
			this.elem.$accents[0].src = `${lPath}accent-${lNum}a.png`;
			this.elem.$accents[1].src = `${lPath}accent-${lNum}.png`;	
			// this.elem.$accents.css({ opacity: 1 });
		}

		if (this.elem.$image.length > 0) {
			this.elem.$image
				.eq(Math.floor(Math.random() * this.elem.$image.length))
				.addClass('masthead__image-show');
		}
	},

	bindUIActions() {
		this.elem.$btn.on('click', this.btnVideo.bind(this));
	},

	btnVideo() {
		this.elem.$btn.attr({
			'aria-label': function (i, txt) {
				return txt.indexOf('Play') > -1
					? txt.replace('Play', 'Pause')
					: txt.replace('Pause', 'Play');
			},
			'aria-pressed': function (i, txt) {
				return txt === 'false' ? 'true' : 'false';
			},
		});
		this.elem.$btn.toggleClass('masthead__btn--is-playing');

		if (this.elem.$btn.hasClass('masthead__btn--is-playing')) {
			this.elem.$video.each(function () {
				this.play();
			});
		} else {
			this.elem.$video.each(function () {
				this.pause();
			});
		}
	},
};

export { masthead };
